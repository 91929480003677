import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const HeroSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true, 
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: true, 
        centerPadding: '0',
        prevArrow: null,
        nextArrow: null, 
        autoplaySpeed: 1000,
        responsive: [
         {
             breakpoint: 768,
             settings: {
                 slidesToShow: 3,
                 slidesToScroll: 1,
             },
         },
         {
             breakpoint: 480,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1,
             },
         },
     ],
    }
  return (
    <section className='container-fliud mt-4'> 
    <div className='row'>

    <div class="col-md-3 col-lg-3  col-sm-12 column">
			  <div class="trust">
					   <span>Trusted by</span>
					   <h4 className='student'>500K+ Student's Of</h4>
				</div>
			 </div>


        <div className='col-md-9 col-lg-9 col-sm-12  column slider-column'>
        <Slider {...settings}>
        <div>
           <img className='h-s-image' src="/assets/image/Durham_University.webp" alt='University Coursework Help'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/Birmingham University.webp" alt='Help with University Coursework'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/Cambridge University.webp" alt='University Coursework Assignment Help'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/Edinburgh University.webp" alt='Online University Coursework Help'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/Bristol University.webp" alt='Best University Coursework Help'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/London University.webp" alt='University Coursework Help Online'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/Manchester University.webp" alt='University Coursework Writing Service'/>
        </div>
        <div>
           <img className='h-s-image' src="/assets/image/Oxford University.webp" alt='University Coursework Writing Service'/>
        </div>
      </Slider>


        </div>
    </div>
    </section>
  )
}

export default HeroSlider