import React from "react";
import { Helmet } from "react-helmet";

const HomePageSeo = () => {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"
      />
      <title>
      Coursework Help UK & Writing Service By Best Expert Writers
      </title>
      <meta
        name="description"
        content="Need coursework help in UK? Our coursework expert writers provide professional coursework writing services ensures top-quality work and HD grades."
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="Coursework Help, Best Coursework Help, Coursework Writing Services, Online Coursework Help, Coursework Help UK, Coursework Experts, Coursework Writer, Coursework Help Online"
      />
      <link rel="canonical" href="https://www.courseworkhelp.uk" />
      <meta property="og:locale" content="en_gb" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Coursework Help UK By Best Coursework Writing Service Online"
      />
      <meta
        property="og:description"
        content="Need coursework help UK? Coursework Help is the best coursework writing service company near London and Manchester, capable of tackling all types of coursework."
      />
      <meta property="og:url" content="https://www.courseworkhelp.uk" />
      <meta property="og:site_name" content="courseworkhelp.uk" />
      <meta property="article:publisher" content="admin" />
      <meta
        property="article:modified_time"
        content="2024-09-07T11:01:28+00:00"
      />
      <meta
        property="og:image"
        content="https://www.courseworkhelp.uk/assets/image/coursework%20help@3x.png"
      />
      <meta property="og:image:width" content="979" />
      <meta property="og:image:height" content="511" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Coursework Help UK By Best Coursework Writing Service Online"
      />
      <meta
        name="twitter:description"
        content="Need coursework help UK? Coursework Help is the best coursework writing service company near London and Manchester, capable of tackling all types of coursework."
      />
      <meta name="twitter:creator" content="Admin" />
      <meta name="twitter:site" content="" />
      <meta name="author" content="Coursework Help" />
    </Helmet>
  );
};

export default HomePageSeo;
